import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SettingsState } from 'src/app/models/settings';


const _selectSettings = createFeatureSelector< SettingsState>('settings');

/**
 * adresse IP enregistrée
 * @return string
 */
export const selectSettingsPrintIP = createSelector(
  _selectSettings,
  (state: SettingsState) => state.ip
);

export const selectShowModalTicket = createSelector(
  _selectSettings,
  (state: SettingsState) => state.showModalTicket
);

export const setShowModalMultiboutiqueFirst = createSelector(
  _selectSettings,
  (state: SettingsState) => state.showModalMultiboutiqueFirst
);
