import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CategoryPriceComponent } from './category-price.component';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomPipeModule
  ],
  declarations: [
    CategoryPriceComponent
  ],
  exports: [
    CategoryPriceComponent
  ]
})
export class CategoryPriceModule { }
