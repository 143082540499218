import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { profilReducer } from './profil/profil.reducer';
import { ProfilState } from '../models/profil';
import { commandeReducer } from './commande/commande.reducer';
import { CommandeState } from '../models/commande';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from '../models/settings';
import { ColorsState } from '../models/colors';
import { colorsReducer } from './colors/colors.reducer';
import { shopReducer } from './shop/shop.reducer';
import { ShopState } from '../models/shop';
import { stripeReducer } from './stripe/stripe.reducer';
import { StripeState } from '../models/stripe';


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ["profil", {commande: ['sound']}, "settings", "colors", "stripe"],
    rehydrate: true,
    storageKeySerializer: (key) => 'floranova_app_' + key
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export interface AppState {
  profil: ProfilState,
  commande: CommandeState,
  settings: SettingsState,
  colors: ColorsState,
  shop: ShopState,
  stripe: StripeState
}

export const reducers: ActionReducerMap<AppState> = {
  profil: profilReducer,
  commande: commandeReducer,
  settings: settingsReducer,
  colors: colorsReducer,
  shop: shopReducer,
  stripe: stripeReducer
};

