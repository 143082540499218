import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { filter, first } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { selectProfilId, selectProfilShopId } from './reducers/profil/profil.selector';
import { select, Store } from '@ngrx/store';
import { selectAnalyticsParameters } from './reducers/shop/shop.selector';
import { Observable } from 'rxjs';

const STORAGE_KEY = "floranova_app_profils"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  parametersAnalytics$: Observable<any> = this.store.select(selectAnalyticsParameters);

  constructor(router: Router, public store: Store){
  
    moment.locale('fr');
    moment.updateLocale('fr',{
      relativeTime : {
        future : 'Reste %s',
        past : 'Il y a %s',
        s : '%d sec.',
        ss : '%d sec.',
        m : 'une min.',
        mm : '%d min.',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
      }
    });

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.code;
    document.head.prepend(script);
    
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', environment.code, {
        'page_path':e.urlAfterRedirects,
      });

      /**
       * Envoi des paramètres à google analytics
       * Id de centre dans la variable CCID
       * id de boutique dans la variable COID
       * id de profil dans la variable PRID
       */
      this.parametersAnalytics$.pipe(first()).subscribe(parameters => {
        gtag('set', {
          'dimension1': parameters.ccid,
          'dimension2': parameters.coid,
          'dimension3': parameters.prid,
        });
      });
    });
  }
}
