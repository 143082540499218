<div class="checklist-modale--item">
    <ng-container class="loader" *ngIf="loader">
        <div class="loader_container">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </ng-container>
    <!-- Pas de produits -->
    <ng-container *ngIf="!loader">
        <ng-container *ngFor="let checkItem of listCheckbox; let i = index">
            <mat-checkbox 
            class="example-margin"
            (change)="toggleCheckbox($event, i)"
            [checked]="checkItem.checked"
            >{{checkItem.label}}</mat-checkbox>
        </ng-container>
        <div class="button_container">
            <mat-checkbox 
            class="example-margin"
            (change)="toggleCheckboxAll($event)"
            >{{stringSelectAll}}
            </mat-checkbox>
            <button 
                mat-flat-button 
                color="primary" 
                class="commande__ready accept" 
                [disabled]="disabledButton" 
                (click)="confirm()" 
            >  
                Confirmer
            </button> 
        </div> 
    </ng-container>
</div>