<div class="ticket-modale--item">
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png.png" alt="">
        </div>
        <p>
            Un commerçant a récemment 
            refusé cette commande.
        </p>

        <p>
            Celle-ci va disparaître de votre liste 
            de commandes pour apparaître 
            dans votre historique en tant 
            que commande « refusée ».
        </p>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="closeModal()">
            J'ai compris
        </button>
    </div>
</div>