import { createAction, props } from '@ngrx/store';
import { Commande, CommandeInfo } from 'src/app/models/commande';

export enum CommandeTypes {
  setInfosCommande = "[Commande] Met à jour les informations générales des commandes",
  toggleNotificationSound = "[Commande] Permute notification sonore active / inactive",
  setPendingCommandsToModal = "[Commande] Affiche les commandes en attente dans la modale",
  addCommandsToModal = "[Commande] Ajoute des commandes dans la modale",
  resetModal = "[Commande] Vide les commandes dans la modale",
  deleteInModal = "[Commande] Supprime une commande de la modale",
  setStartIdModal = "[Commande] Définit le slide à montrer au départ du slider de modal",
  addInPending = "[Commande] Ajoute des commandes dans 'pending'",
  setPending = "[Commande] Met à jour les commandes de 'pending'",
  deleteInPending = "[Commande] Supprime une commande de 'pending'",
  addInAccepted = "[Commande] Ajoute des commandes dans 'accepted'",
  deleteInAccepted = "[Commande] Supprime une commande de 'accepted'",
  setAccepted = "[Commande] Remplace les commandes de 'accepted'",
  addInArchived = "[Commande] Ajoute des commandes dans 'archived'",
  setArchived = "[Commande] Remplace les commandes de 'archived'",
  setNewLeader = "[Commande] On passe le commerçant en leader de la commande multiboutique",
  updateOrderQuantities = "[Commande] On met à jour les quantités produits dans la commande",
  refreshPendingCommandInModal = "[Commande] On met à jour les modales en pending "
}

/**
 * GENERAL
 */
// Met à jour les informations générales des commandes
export const setInfosCommande = createAction(
  CommandeTypes.setInfosCommande,
  props<{ info: CommandeInfo }>()
);

// Met à jour les informations générales des commandes
export const toggleNotificationSound = createAction(
  CommandeTypes.toggleNotificationSound
);



/**
 * SHOW IN MODAL
 */
// Affiche les commandes en attente dans la modale
export const setPendingCommandToModal = createAction(
  CommandeTypes.setPendingCommandsToModal,
  props<{ id: string }>()
);

// Ajoute des commandes dans la modale
export const addCommandsToModal = createAction(
  CommandeTypes.addCommandsToModal,
  props<{ commandes: Commande[] }>()
);

// Vide les commandes dans la modale
export const resetModal = createAction(
  CommandeTypes.resetModal
);

// Supprime une commande de la modale
export const deleteInModal = createAction(
  CommandeTypes.deleteInModal,
  props<{ id: string }>()
);


/**
 * PENDING
 */
// Ajoute des commandes dans 'pending'
export const addInPending = createAction(
  CommandeTypes.addInPending,
  props<{ commandes: Commande[] }>()
);

// Remplace les commandes de 'pending'
export const setPending = createAction(
  CommandeTypes.setPending,
  props<{ commandes: Commande[] }>()
);

// Supprime une commande de 'pending'
export const deleteInPending = createAction(
  CommandeTypes.deleteInPending,
  props<{ id: string }>()
);

// Supprime une commande de 'pending'
export const refreshPendingCommandInModal = createAction(
  CommandeTypes.refreshPendingCommandInModal,
  props<{ commandes: Commande[], id: string }>()
);


/**
 * ACCEPTED
 */
// Ajoute des commandes dans 'accepted'
export const addInAccepted = createAction(
  CommandeTypes.addInAccepted,
  props<{ commandes: Commande[] }>()
);

// Supprime une commande de 'accepted'
export const deleteInAccepted = createAction(
  CommandeTypes.deleteInAccepted,
  props<{ id: string }>()
);

// Remplace les commandes de 'accepted'
export const setAccepted = createAction(
  CommandeTypes.setAccepted,
  props<{ commandes: Commande[] }>()
);


/**
 * ARCHIVED
 */
// Ajoute des commandes dans 'archived'
export const addInArchived = createAction(
  CommandeTypes.addInArchived,
  props<{ commandes: Commande[] }>()
);

// Remplace les commandes de 'archived'
export const setArchived = createAction(
  CommandeTypes.setArchived,
  props<{ commandes: Commande[] }>()
);

/**
 * MULTIBOUTIQUE
 */
// Passe le status de chef de commande à 1
export const setNewLeader = createAction(
  CommandeTypes.setNewLeader,
  props<{ commande: Commande }>()
);

export const updateOrderQuantities = createAction(
  CommandeTypes.updateOrderQuantities,
  props<{ commande: Commande, arrayName: string }>()
);