import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ChronopostConfig, Commande } from 'src/app/models/commande';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  UntypedFormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
  FormBuilder,
  FormControl
} from '@angular/forms';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { ChronopostService } from 'src/app/utils/chronopost/chronopost.service';
import { selectConfigChronopost } from 'src/app/reducers/commande/commande.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'chronopost-modal',
  templateUrl: './chronopost-modal.component.html',
  styles: []
})
export class ChronopostModalComponent implements OnInit {
  chronopostForm: FormGroup;
  payload: any;
  commande: Commande
  isLoading: boolean = false;
  configChronopost$: Observable<ChronopostConfig> = this.store.select(selectConfigChronopost);

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public commandesService: CommandesService,
    private http: HttpClient,
    public store: Store,
    private fb: FormBuilder,
    private chronopostService: ChronopostService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChronopostModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Commande
    ) {}

  ngOnInit() {

    this.chronopostForm = this.fb.group({
      multi: 0,
      dimensions: this.fb.array([]),
    })
  }

  get dimensions() : FormArray {
    return this.chronopostForm.get("dimensions") as FormArray
  }

  newDimensions(): FormGroup {
    return this.fb.group({
      weight: new FormControl(this.data.default_weight),
      length: new FormControl(40.7),
      height: new FormControl(34.4),
      width: new FormControl(40.7),
    })
  }

  addDimensions() {
    this.dimensions.push(this.newDimensions());
    this.chronopostForm.setValue({multi:this.chronopostForm.controls['multi'].value+1,dimensions:this.chronopostForm.controls['dimensions'].value});
  }

  removeDimensions(i:number) {
    this.dimensions.removeAt(i);
    this.chronopostForm.setValue({multi:this.chronopostForm.controls['multi'].value-1,dimensions:this.chronopostForm.controls['dimensions'].value});
  }

  disableButton() {
    return this.chronopostForm.controls['multi'].value == 0
  }

  submit() {
    this.isLoading = true;

    this.configChronopost$.pipe(
      take(1)
    ).subscribe(
      config => {
        let payload = {
          orderid: this.data.order_id,
          shared_secret: config.sharedKey,
          account: config.contract,
          multiOne: this.chronopostForm.controls['multi'].value,
        }
        let formData :any = new FormData();
        Object.keys(payload).forEach(key => {
          formData.append(key,payload[key]);
        });
        this.chronopostForm.controls['dimensions'].value.forEach((element) => {
          formData.append("weight[]", element.weight);
          formData.append("length[]", element.length);
          formData.append("height[]", element.height);
          formData.append("width[]", element.width);
        });

        const asString = new URLSearchParams(formData).toString();

        this.http.post<Blob>(`${environment.back}modules/chronopost/postSkybill.php`, asString, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Headers':'Content-Type, Authorization',
            'Accept': 'application/blob',
          }),
          responseType: 'blob' as 'json'
        }).subscribe({
          next: data => {
            var file = new Blob([data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.dialogRef.close();
            this.isLoading = false;
            this.router.navigate(['/historique/'+this.data.order_id])
          },
          error: error => {
            this.dialogRef.close();
            this.isLoading = false;
            window.location.reload();
          }
        });
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }
}
