<div class="commande-modale" *ngIf="enabled$ | async">
    <div class="commande-modale--backdrop" (click)="close()"></div>
    <div class="commande-modale--wrapper">
        <owl-carousel-o [options]="customOptions" #owlCar (changed)="getData($event)">
            <ng-container *ngFor="let slide of editableOrdersForQuantities; let i = index">
                <ng-template carouselSlide [id]="'slide_'+slide.order_id">
                    <div class="commande-modale--item">
                        <section class="item--head">
                            <button mat-icon-button class="close__modale" (click)="closeModale(slide)"></button>
                            <div class="item--id_container">
                                <span class="item--id"><strong>N°{{slide.order_id}}</strong></span>
                                <span class="item--id--date">
                                    Pour le {{moment(slide.delivery_date).format("DD/MM/YY")}} 
                                    à {{ slide | preparationHour | async | hour }}
                                </span>
                            </div>
                            <div class="item__time__container">
                                <div class="item--time--left">
                                    <p class="item--customer">
                                        {{slide?.address?.customer_firstname}} {{slide?.address?.customer_lastname}}
                                        <span>{{slide?.address?.customer_telephone}}</span>
                                    </p>
                                    <ng-container *ngIf="slide?.delivery_type != 'click_and_collect'">
                                        <span class="item--delivery">
                                            {{slide?.delivery_address?.city}} ({{slide?.delivery_address?.postcode}})
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <category-price [price]="slide.total" [type]="slide.delivery_type"></category-price>
                        </section>
                        <div class="item__list">
                            <section class="item--list" [id]="'scrollable_content_'+slide.order_id">
                                <div class="historique__details__list">
                                    <ng-container *ngIf="slide?.items?.categories.length > 0">
                                        <div class="historique__details--cat"
                                            *ngFor="let cat of slide.items.categories">
                                            <!-- <span class="category">{{cat.type}}</span> -->
                                            <span class="category">Produits</span>
                                            <mat-list-item *ngFor="let choice of cat.choices; let last = last">
                                                <div class="line" [ngClass]="{ last: last }">
                                                    <button type="button"
                                                        (click)="changeQtyProduct(slide, cat, choice, i)"
                                                        *ngIf="editModeIds.includes(slide.order_id) && choice.qty > 0"
                                                        class="line_less_button">-</button>
                                                    <div class="line--img">
                                                        <img src="{{choice.img}}" alt="{{choice.name}}" />
                                                    </div>
                                                    <div class="line--desc">
                                                        <div class="line--qty">x{{choice.qty}}</div>
                                                        <div class="line--name">
                                                            {{choice.name}}
                                                            <p class="line--config" *ngIf="choice.config">
                                                                {{choice.config}}</p>
                                                        </div>
                                                        <div class="line--total">{{choice.total | roundUpPrice}} €</div>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </ng-container>
                                </div>
                            </section>
                        </div>
                        <section class="item--foot">
                            <button mat-flat-button class="commande__canceled close" (click)="refuse(slide)">
                                <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="close"></mat-icon>Refuser
                            </button>
                            <ng-container *ngIf="!editModeIds.includes(slide.order_id)">
                                <loader></loader>
                            </ng-container>
                            <!-- <button *ngIf="!editModeIds.includes(slide.order_id)" mat-flat-button color="secondary" class="edit_button" (click)="edit(slide)">
                                <mat-icon aria-hidden="true" class="ok">edit</mat-icon><span class="edit_button_label"> Modifier</span>
                            </button> -->
                            <button *ngIf="!editModeIds.includes(slide.order_id)" mat-flat-button color="primary"
                                class="commande__ready accept" (click)="accept(slide)" [disabled]="isRequesting">
                                <mat-icon aria-hidden="true" class="ok">done</mat-icon> Accepter
                            </button>
                            <button *ngIf="editModeIds.includes(slide.order_id)" mat-flat-button color="primary"
                                class="commande__ready accept" (click)="validate(slide)" [disabled]="isRequesting">
                                <mat-icon aria-hidden="true" class="ok">done</mat-icon> Valider
                            </button>
                        </section>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>