import { createAction, props } from '@ngrx/store';

export enum SettingsTypes {
  setIp = "[Settings] Met à jour l'ip imprimante'",
  setShowModalTicket = "[Settings] Désactive l'affichage de la modale ticket de caisse (colissimo)",
  setShowModalMultiboutiqueFirst = "[Settings] Désactive l'affichage de la modale responsable envoie colis"
}
export const setIp = createAction(
  SettingsTypes.setIp,
  props<{ ip: string }>(),
);

export const setShowModalTicket = createAction(
  SettingsTypes.setShowModalTicket  
);

export const setShowModalMultiboutiqueFirst = createAction(
  SettingsTypes.setShowModalMultiboutiqueFirst  
);

