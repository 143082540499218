<div class="confirmation_container">
    <p *ngIf="!hasDiscount">
        Pensez à contacter le client au {{data.address.customer_telephone}} pour le prévenir de cette mise à jour du panier. <br/>
        Êtes vous sûr(e) de vos modification ?
    </p>
    <p *ngIf="hasDiscount">
        ATTENTION ! Cette vente bénéficie d’une promotion ! <br/>
        Êtes vous sûr(e) de vouloir modifier la commande <br/>
        Contactez le client au {{data.address.customer_telephone}} pour supprimer / remplacer le produit manquant ou l’informer de la perte de la promotion en cours
    </p>
    <div class="button_container">
        <button mat-flat-button color="warn" class="commande__ready delete" (click)="closeModal()">  
            Annuler
        </button>   
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="acceptAndCloseModal()">  
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>
            Confirmer   
        </button> 
    </div>  
</div>