import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { CommandeDate, dataPagination } from 'src/app/models/commande';
import { selectCommandeArchived } from 'src/app/reducers/commande/commande.selector';
import { Store } from '@ngrx/store';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styles: [
  ]
})
export class HistoriqueComponent implements OnInit, OnDestroy {
  interval: number = 120000
  subscription: Subscription;
  commandes$: Observable<CommandeDate[]> = this.store.select(selectCommandeArchived)
  dataPagination$: Observable<dataPagination>
  search: {key:string, value: string}

  constructor(
    private store: Store,
    private commandesService: CommandesService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subscription = timer(0, this.interval).pipe(
      switchMapTo(this.route.queryParams)
    ).subscribe((queryParams:Params) => {
      this.dataPagination$ = this.commandesService.refreshArchivedV2(queryParams)
      
      if(queryParams.id && queryParams.id !== "")
        this.search = {key: 'id', value: queryParams.id}
      else if(queryParams.date && queryParams.date !== "")
        this.search = {key: 'date', value: queryParams.date}
      else
        this.search = null;
    })
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
