<div *ngIf="order; else noOrder;">
  <div class="commande__details__timer historique">
    <div class="historique__actions">
      <a mat-flat-button color="none" *ngIf="showFraisButton() | async" class="commande__print historique refund"
        (click)="openModaleFraisCommission()">
        <mat-icon aria-hidden="true" fontSet="fontello">list_alt</mat-icon> Demander un remboursement des <span
          class="hide_for_mobile">frais de</span> commissions
      </a>
      <!-- <a mat-flat-button color="none" *ngIf="showReprintButton() | async" class="commande__print historique reprint" (click)="reprint()">
        <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="print"></mat-icon> Générer à nouveau l'étiquette colissimo
      </a> -->
      <!-- <a mat-flat-button color="none" class="commande__print historique" (click)="print()">
        <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="print"></mat-icon> Imprimer
      </a> -->
    </div>
    <div class="commande_details_infos">
      <div class="commande__details--id-container">
        <span class="commande__details--id">COMMANDE N°{{ order.order_id }}</span>
        <span class="commande__details--id-label">Ref commande : {{ order.reference }}</span>
        <ng-container *ngIf="((order$ | async).shop_discount?.amount | stringToNumber) > 0">
          <div class="commande__details--discount">
            {{ (order$ | async).shop_discount?.rules_name }} : {{ (order$ | async).shop_discount?.amount |
            number:'1.2-2' }}€
          </div>
        </ng-container>
      </div>


      <div class="commande__details--multiboutique leader">
        <a class="commande__details--multiboutique--button" (click)="openModaleMultiboutique()"
          *ngIf="(order$ | async).sub_orders_related.length > 0">
          <span>{{getStringAcceptedOrder(order)}}</span>
          <img src="assets/images/king.png" alt="" *ngIf="getOrderLeader() | async">
        </a>
      </div>

      <div class="time" *ngIf="(order$ | async).delivery_type != 'chronopost'">
        <span>Pour le {{order.delivery_date | daySidebar:formatToday}} à {{ (order$ | async) |
          preparationHour | async | hour }}</span>
      </div>
      <category-price [price]="order.total" [type]="order.delivery_type"></category-price>
    </div>
  </div>
  <div class="commande__phone">
    {{getStringPhoneName() | async}}
    <!-- <span *ngIf="order.refuse_reason">Motif : {{order.refuse_reason}}</span> -->
    <div>
      <a  mat-flat-button color="none"
      class="commande_pdf" (click)="getBonChronopost(order)"
      *ngIf="order.delivery_type == 'chronopost'">
      Bon chronopost
      </a>
      <a
      mat-flat-button color="none"
      class="commande_pdf"
      (click)="getPdfDelivery(order)"
      >
        PDF de livraison
      </a>
      <a
        mat-flat-button color="none"
        class="commande_pdf"
        (click)="getInvoice(order)"
      >
        PDF de facture
      </a>
    </div>

  </div>
  <div class="historique__details__list">
    <div class="historique__details--menus" *ngIf="order.items.menus.length > 0">
      <span class="category">Menu</span>
      <mat-list-item *ngFor="let menu of order.items.menus; let last = last">
        <div class="line" [ngClass]="{ last: last }">
          <div class="line--qty">x{{menu.qty}}</div>
          <div class="line--name">{{menu.name}}</div>
          <div class="line--total">{{menu.total}} €</div>
        </div>
        <div class="menu__details">
          <ng-container *ngFor="let info of menu.bundle_info">
            <div class="menu__details--type">{{info.type}}</div>
            <ng-container *ngFor="let choice of info.choices">
              <div class="menu__details--name">{{choice.name}}</div>
            </ng-container>
          </ng-container>
        </div>
      </mat-list-item>
    </div>
    <ng-container *ngIf="order.items.categories.length > 0">
      <div class="historique__details--cat" *ngFor="let cat of order.items.categories">
        <span class="category">Produits</span>
        <mat-list-item *ngFor="let choice of cat.choices; let last = last">
          <div class="line" [ngClass]="{ last: last }">
            <!-- <button (click)="changeQtyProduct(cat, choice)" *ngIf="editMode && choice.qty > 0" class="line_less_button">-</button> -->
            <div class="line--img">
              <img src="{{choice.img}}" alt="{{choice.name}}" />
            </div>
            <div class="line--desc">
              <div class="line--qty">x{{choice.qty}}</div>
              <div class="line--name">
                {{choice.name}}
                <p class="line--config">{{choice.config}}</p>
              </div>
              <div class="line--total">{{choice.total | roundUpPrice}} €</div>
            </div>
          </div>
        </mat-list-item>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="(order$ | async).merchant_information">
    <div class="complementary__information">
      <span class="message">Message: {{ (order$ | async).merchant_information }}</span>
    </div>
  </ng-container>
</div>




<ng-template #noOrder>
  <div class="commande__empty">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
