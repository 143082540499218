<div class="colissimo-modale--item">
    <section class="item--head">
        <span class="">Générer l’étiquette de livraison Colissimo </span>
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section>
    <div class="item__list">
        <section class="item--list">
            <div class="historique__details__list">
                <pdf-viewer 
                    [src]="pdfContent" 
                    [render-text]="true"
                    style="display: block;"
                ></pdf-viewer>
            </div>
        </section>
    </div>
    <section class="buttons">
        <div class="actions_buttons">
            <a mat-flat-button color="none" class="commande__print" (click)="mail()">
              <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="print"></mat-icon> Envoyer par mail
            </a>
            <!-- <a mat-flat-button color="none" class="commande__print" target="_blank" [href]="pdfSrc">
              <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="print"></mat-icon> Imprimer
            </a> -->
        </div>
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="send()">
            <mat-icon aria-hidden="true" class="ok">done</mat-icon> Terminer
        </button>
    </section>
</div>