import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectProfilComponent } from './select-profil/select-profil.component';
import { MaterialModule } from 'src/app/material.module';
import { CommandeModule } from './commande/commande.module';
import { HistoriqueModule } from './historique/historique.module';
import { InAppRoutingModule } from './in-app-routing.module';
import { AppCommandeModaleModule } from 'src/app/_layout/app-commande-modale/app-commande-modale.module';
import { AppLayoutComponent } from 'src/app/_layout/app-layout/app-layout.component';
import { AppHeaderComponent } from 'src/app/_layout/app-header/app-header.component';
import { AppNavigationComponent } from 'src/app/_layout/app-navigation/app-navigation.component';
import { ReportingComponent,   } from './reporting/reporting.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { SettingsComponent } from './settings/settings.component';
import { LoaderModule } from 'src/app/_components/loader/loader.module';

@NgModule({
   imports: [
      CommonModule,
      ReactiveFormsModule,
      MaterialModule,
      //routing
      InAppRoutingModule,
      CommandeModule,
      HistoriqueModule,
      AppCommandeModaleModule,
      CustomPipeModule,
      LoaderModule
   ],
   declarations: [
      SelectProfilComponent,
      ReportingComponent, 
      AppLayoutComponent,
      AppHeaderComponent,
      AppNavigationComponent,
      SettingsComponent,
   ]
})
export class InAppModule { }
