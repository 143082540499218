import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { Store, select } from '@ngrx/store';
import { switchMap, first, take, share, map } from 'rxjs/operators';
import { selectCommandeByIdAndType } from 'src/app/reducers/commande/commande.selector';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { MatDialog } from '@angular/material/dialog';
import { ReprintColissimoModalComponent } from 'src/app/_components/reprint-colissimo-modal/reprint-colissimo-modal.component';
import { ColissimoService } from 'src/app/utils/colissimo/colissimo.service';
import { MultiboutiqueListModalComponent } from 'src/app/_components/multiboutique-list/multiboutique-list-modal.component';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FraisCommissionModalComponent } from 'src/app/_components/frais-commission-modal/frais-commission-modal.component';
import * as moment from 'moment';
import { ChronopostModalComponent } from 'src/app/_components/chronopost-modal/chronopost-modal.component';
import { environment } from 'src/environments/environment';

const STORAGE_KEY = "floranova_app_profil"

@Component({
  selector: 'app-historique-details',
  templateUrl: './historique-details.component.html',
  styles: [],
  host: {
    class: 'historique__details'
  }
})
export class HistoriqueDetailsComponent implements OnInit, OnDestroy {
  formatToday:string
  order$: Observable<Commande>
  order: Commande
  orderSubscription: Subscription
  shopId = this.storage.get(STORAGE_KEY)?.shop.id_shop
  moment: any = moment;
  
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store,
    private commandesService: CommandesService,
    private colissimoService: ColissimoService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  ngOnInit() {
    // Récupère dans le store la commande en fonction de l'id passé en param
    this.order$ = this.route.paramMap.pipe(
      switchMap(
        param => this.store.select(selectCommandeByIdAndType(param.get('id'), 'archived'))  
      )
    )
    this.orderSubscription = this.order$.subscribe(
      commande => this.order = commande
    )
  }

  showReprintButton(): Observable<boolean>{
    return this.order$.pipe(
      take(1),
      map(order => {
        if(order.delivery_type == 'chronopost'){
          //SI MULTIBOUTIQUE + STATUS EXPEDIEE + LEADER COMMANDE => OK
          if(order.sub_orders_related.length > 0){
            if(order.status == 'shipped' && order.is_primary == '1')
              return true;
            else return false;
          }
          else return true;
        }
        //AUTRES TYPES DE COMMANDE
        else return false;
      })
    )
  }

  showFraisButton(): Observable<boolean>{
    return this.order$.pipe(
      take(1),
      map(order => {
        return (order.status == 'ready' || order.status == 'shipped') 
        && (parseInt(order.fees_rate) > 0) 
        && order.remaining_refund_items == true
      })
    )
  }

  openModaleFraisCommission(){        
    const dialogRef = this.dialog.open(FraisCommissionModalComponent, {
      panelClass: "modal_frais_comission",
      data: this.order$
    });
  }

  getBonChronopost(order: Commande){
    //Si on l'a déjà ouvert on récupère juste la ref de l'étiquette pour l'afficher dans un nouvel onglet
    if(order.delivery_skybill_history.lt){
      return window.open(`${environment.back}modules/chronopost/skybills/${order.delivery_skybill_history.lt}`);
    }

    const dialogRef2 = this.dialog.open(ChronopostModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: order
    });
  }

  getPdfDelivery(order: Commande){
      this.commandesService.getPdfDelivery(order.reference).subscribe(
        pdf => {
          let blob = new Blob([pdf], {type: 'application/pdf'});
          var downloadURL = URL.createObjectURL(pdf);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = `commande_${order.reference}.pdf`;
          return link.click();
        }
      );
  }

  getInvoice(invoice: Commande){
    this.commandesService.getInvoice(invoice.reference).subscribe(
      pdf => {
        let blob = new Blob([pdf], {type: 'application/pdf'}); 
        var downloadURL = URL.createObjectURL(pdf);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `facture_${invoice.reference}.pdf`;
        return link.click();
      }
    )
  }

  reprint(): void {
    this.order$.pipe(
      take(1)
    ).subscribe(
      order => {
        const dialogRef = this.dialog.open(ReprintColissimoModalComponent, {
          panelClass: "modal__colissimo",
          data: this.order$
        }); 
        this.colissimoService.getPdfColissimo(order.order_id)
    })
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe()
  }

  /**
   * Savoir si on est leader de la commande
   */
  getOrderLeader(): Observable<boolean> {
    return this.order$.pipe(
      take(1),
      map(order => {
        if(order.sub_orders_related.length > 0 && order.delivery_type == 'chronopost'){
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === this.shopId
          }) 
          if(findIndexShop !== -1){
            if(order.sub_orders_related[findIndexShop].is_primary === '1'){
              return true;
            }
          }
        }
      })
    )
  }

  getStringAcceptedOrder(order: Commande): string{
    if(order.sub_orders_related.length > 0){
      let numberAcceptedOrder = 0;
      order.sub_orders_related.forEach(el => {
        if(el.status == 'accepted' || el.status == 'ready' || el.status == 'shipped')
          numberAcceptedOrder++
      })
      return `${numberAcceptedOrder}/${order.sub_orders_related.length} acceptés`
    }
  }

  getStringPhoneName(): Observable<string>{
    return this.order$.pipe(
      take(1),
      map(order => {
        return `Client : ${order.address.customer_firstname} ${order.address.customer_lastname.charAt(0)}. (${order.address.customer_telephone})`
      })
    )
  }

  openModaleMultiboutique(){        
    const dialogRef = this.dialog.open(MultiboutiqueListModalComponent, {
      panelClass: "modal__multiboutique",
      data: this.order$
    });
  }
  
  print(){
    this.order$.pipe(
      take(1)
    ).subscribe(
      order => {
        // this.printService.print(order,1)
      }
    )
  }

}
