import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// import { PrintService } from 'src/app/utils/print/print.service';

@Component({
  selector: 'edit-order-no-product-left-modal',
  templateUrl: './edit-order-no-product-left-modal.component.html',
  styles: []
})
export class EditOrderNoProductLeftModal implements OnInit {
  hasDiscount: boolean;
  constructor(
    public dialogRef: MatDialogRef<EditOrderNoProductLeftModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){}

  ngOnInit() {
    
  }

  acceptAndCloseModal() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
