import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande, CommandeMultiboutique } from 'src/app/models/commande';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'multiboutique-list-modal',
  templateUrl: './multiboutique-list-modal.component.html',
  styles: []
})
export class MultiboutiqueListModalComponent implements OnInit {
  subscription: Subscription;
  listUser: CommandeMultiboutique[]
  commande: Commande

  constructor(
    public router: Router,
    public store: Store,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MultiboutiqueListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<Commande>
    ) { }

  ngOnInit() {
    this.data.pipe(
      take(1)
    ).subscribe(
      data => {
        this.commande = data;
        this.listUser = data.sub_orders_related
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }
}
