<div class="confirmation_container">
    <p>Êtes vous sûr(e) de vouloir {{getString()}} tous les produits ?</p>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="acceptAndCloseModal()">  
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>
            Confirmer   
        </button> 
        <button mat-flat-button color="warn" class="commande__ready delete" (click)="closeModal()">  
            Annuler
        </button>   
    </div>  
</div>