import { Component, OnInit, Input } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Commande } from 'src/app/models/commande';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { Observable, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'sidebar-line',
  templateUrl: './sidebar-line.component.html',
  styles: []
})
export class SidebarLineComponent implements OnInit {
  timerWidth$: Observable<number>;
  queryParams$ : Observable<Params>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commandeService: CommandesService
  ) { }

  ngOnInit() {
    this.getTimer(this.order)
    this.queryParams$ = this.route.queryParams
  }

  getTimer(order: Commande){
    this.timerWidth$ = timer(0, 3000).pipe(
      switchMapTo(this.commandeService.timer(order))
    )
  }

  

  @Input() wide: boolean
  @Input() order: Commande

  redirect(id:string) {
    let path = this.wide ? "historique" : "commande"
    this.router.navigate([path, id])
  }
}
