import { createReducer, on, Action } from '@ngrx/store';
import * as CommandeAction from './commande.action'
import { CommandeState, Commande, CommandeDate, CommandeMenu, CommandeMenuType } from 'src/app/models/commande';
import * as moment from 'moment'; 

export const initialState: CommandeState = {
  showInModal: [],
  idStartInModal: null,
  pending: [],
  accepted: [],
  archived: [],
  info: {
    delivery_time: "25",
    nb_orders: 0, // inutile
    nb_received_orders_last_week: 0,
    nb_received_orders_today: 0,
    preparation_time: "25",
    chronopostConfig: {
      contract: 0,
      sharedKey: "",
      skybillUrl: ""
    },
    total_orders: "0,00", // inutile
  },
  sound: true
};

/**
 * Supprime un element d'un tableau grâce à son id
 */
const deleteIn = (state: CommandeState, id:string, arrayName: string): CommandeState => {
  const arrayDate: CommandeDate[] = [...state[arrayName]]
  let result = []
  arrayDate.some((commandeDate, index1) => {
    const index2 = commandeDate.orders.findIndex(el => el.order_id === id)
    if(index2 !== -1){
      // On supprime l'element de façon immuable  
      // On en profite pour mettre à jour le total
      result = [
        ...arrayDate.slice(0,index1),
        {
          ...arrayDate[index1], 
          orders: [
            ...arrayDate[index1].orders.slice(0,index2),
            ...arrayDate[index1].orders.slice(index2+1)
          ],
          total: commandeDate.orders[index2].status == 'canceled' || commandeDate.orders[index2].status == 'refused' ? arrayDate[index1].total : arrayDate[index1].total - totalToNumber(commandeDate.orders[index2].total)
        },
        ...arrayDate.slice(index1+1)
      ]
      // return true pour stopper l'itération
      return true
    }
  })

  if(result.length){
    return {...state, [arrayName]: result}
  }
  else{
    return {...state}
  }
}

/**
 * Trie les menus pour regrouper les éléments qui ont le même type.
 * 
 */
const format = (commande: Commande) => {
  let menus = commande.items.menus;
  if(menus.length){
    let newMenuArray: CommandeMenu[] = [];

    menus.forEach(menu => {
      let newBundleInfoArray: CommandeMenuType[] = [];
      menu.bundle_info.forEach(info => {
        let index = newBundleInfoArray.findIndex((element) => element.type === info.type)
        if(index === -1)
          newBundleInfoArray.push(info);
        else
          newBundleInfoArray[index] = {...newBundleInfoArray[index], choices: [...newBundleInfoArray[index].choices, ...info.choices]}
      })
      newMenuArray.push({...menu, bundle_info: newBundleInfoArray})
    })
    let newCommande: Commande = {...commande, items: {...commande.items, menus: newMenuArray}}
    return newCommande
  }else{
    return commande;
  }
}

/**
 * Ajoute des commandes par date
 */
const addIn = (state: CommandeState, commandes: Commande[], arrayName: string, idStartInModal?: string): CommandeState => { 
  // const startTraitement = Date.now()
  
  let arrayDate: CommandeDate[] = [...state[arrayName]]
  if(commandes){
    // On boucle sur toutes les commandes reçues
    commandes.forEach(commande => {
      let date: string;
      if (commande?.delivery_date) {
        date = commande.delivery_date.split(" ")[0];
      } else {
        date = "Date invalide";
      }
      // On check si la date n'existe pas déjà
      const indexDate: number = arrayDate.findIndex(el => el.date === date)
      if(indexDate === -1){
        // Nouvelle date: On ajoute à la suite de notre tableau 'CommandeDate'
        arrayDate.push({
          date: date,
          total: commande.status == "canceled" || commande.status == "refused" ? totalToNumber('0') : totalToNumber(commande.total),
          orders: [format(commande)]
        })
      }
      else {
        // Date déjà existante:
        // évite les doublons de commande
        const newOrder = arrayDate[indexDate].orders.findIndex(el => el.order_id === commande.order_id) === -1
        if(newOrder){
          // On ajoute la nouvelle commande
          // On trie l'heure des commandes en même temps
          arrayDate = [
            ...arrayDate.slice(0,indexDate),
            {
              ...arrayDate[indexDate], 
              orders: sortBydate([...arrayDate[indexDate].orders, format(commande) ], 'delivery_date', true, parseInt(state.info.delivery_time))
            },
            ...arrayDate.slice(indexDate+1),
          ]
          // On additionne le total
          commande.status == "canceled" || commande.status == "refused" ? arrayDate[indexDate].total : arrayDate[indexDate].total += totalToNumber(commande.total)
        }
      }
    })
  }
  // On trie la date des commandes
  // Descendant pour 'archived' , Ascendant pour les autres
  arrayDate = sortBydate(arrayDate, 'date', arrayName !== "archived")
  if(idStartInModal)
    return {...state, [arrayName]: arrayDate, idStartInModal}
  return {...state, [arrayName]: arrayDate}
}

/**
 * trie par ordre chronologique
 */
const sortBydate = (array: any[], key:string, asc = true, delivery_time? :number): any[] => {
  const sortedArray = array.sort((a, b) => {
    let momentA, momentB;
    if(key === 'delivery_date'){
      if(a.delivery_type === 'home_delivery') {
        momentA = moment(a[key]).subtract(delivery_time, 'minutes')
      } else {
        momentA = moment(a[key])
      }
      if(b.delivery_type === 'home_delivery') {
        momentB = moment(b[key]).subtract(delivery_time, 'minutes')
      } else {
        momentB = moment(b[key])
      }
    } else {
      momentA = moment(a[key])
      momentB = moment(b[key])
    }
    return asc ? momentA.diff(momentB) : momentB.diff(momentA)
  })
  return sortedArray
}

const totalToNumber = (total:string): number => {
  return parseFloat(total.replace(/,/g, '.'))
}

const editExistingCommande = (state: CommandeState, commande: Commande) => {
  const date:string = commande?.delivery_date?.split(" ")[0]
  // On check si la date dans le tableau accepted
  const indexDate: number = state.accepted.findIndex(el => el.date === date)
  let arrayCommandeAccepted = state.accepted[indexDate].orders;
  let arrayDate: CommandeDate[] = [...state['accepted']]
  //On cherche l'index du profil à modifier
  const existingCommande = state.accepted[indexDate].orders.findIndex(el => el.order_id === commande.order_id)

  //On ajoute les nouvelles infos du profil à l'index
  arrayCommandeAccepted = [
    ...arrayCommandeAccepted.slice(0, existingCommande),
    {
      ...commande
    },
    ...arrayCommandeAccepted.slice(existingCommande+1),
  ]

  const newOrder = arrayDate[indexDate].orders.findIndex(el => el.order_id === commande.order_id) === -1
  if(newOrder){
    // On ajoute la nouvelle commande
    // On trie l'heure des commandes en même temps
    arrayDate = [
      ...arrayDate.slice(0,indexDate),
      {
        ...arrayDate[indexDate], 
        orders: arrayCommandeAccepted
      },
      ...arrayDate.slice(indexDate+1),
    ]
  }
  
  //On retourne à nouveau le tableau de commande  
  return {...state, accepted: arrayDate}
}

const updateQuantitiesCommande = (state: CommandeState, commande: Commande, arrayName: string) => {
  const date:string = commande?.delivery_date?.split(" ")[0]
  // On check si la date dans le tableau accepted
  const indexDate: number = [...state[arrayName]].findIndex(el => el.date === date)
  let arrayCommande = [...state[arrayName]][indexDate].orders;
  let arrayDate: CommandeDate[] = [...state[arrayName]]
  //On cherche l'index du profil à modifier
  const existingCommande = [...state[arrayName]][indexDate].orders.findIndex(el => el.order_id === commande.order_id)

  arrayDate = [
    ...arrayDate.slice(0,indexDate),
    {
      ...arrayDate[indexDate], 
      orders: [
        ...arrayCommande.slice(0, existingCommande),
        {
          ...commande
        },
        ...arrayCommande.slice(existingCommande+1),
      ]
    },
    ...arrayDate.slice(indexDate+1),
  ]  
  //On retourne à nouveau le tableau de commande  
  return {...state, [arrayName]: arrayDate}
}


const _commandeReducer = createReducer(
  initialState,
  on(CommandeAction.setInfosCommande, 
    (state, {info}) => {
      return ({...state, info})
    }),
  on(CommandeAction.toggleNotificationSound, 
    (state) => {
      return ({...state, sound: !state.sound})
    }),
  on(CommandeAction.setPendingCommandToModal, 
    (state, { id }) => {
      return ({...state, showInModal: state.pending, idStartInModal: id})
    }),
  on(CommandeAction.refreshPendingCommandInModal, 
    (state, { commandes, id }) => {
      const emptyState = {...state, showInModal: []}
      return addIn(emptyState, commandes, 'showInModal', id)
    }),
  on(CommandeAction.addCommandsToModal, 
    (state, { commandes }) => {
      return addIn(state, commandes, 'showInModal')
    }),
  on(CommandeAction.resetModal, 
    (state) => {
      return ({...state, showInModal: [], idStartInModal: null})
    }),
  on(CommandeAction.deleteInModal, 
    (state, {id}) => {
      return(deleteIn(state,id,'showInModal'))
    }),
  on(CommandeAction.addInPending, 
    (state, {commandes}) => {
      return addIn(state, commandes, 'pending')
    }),
  on(CommandeAction.setPending, 
    (state, {commandes}) => {
      const emptyState = {...state, pending: []}
      return addIn(emptyState, commandes, 'pending')
    }),
  on(CommandeAction.deleteInPending, 
    (state, {id}) => {
      return (deleteIn(state,id,'pending'))
    }),
  on(CommandeAction.addInAccepted, 
    (state, {commandes}) => {
      return addIn(state, commandes, 'accepted')
    }),
  on(CommandeAction.deleteInAccepted, 
    (state, {id}) => {
      return (deleteIn(state,id,'accepted'))
    }),
  on(CommandeAction.setAccepted, 
    (state, {commandes}) => {
      const emptyState = {...state, accepted: []}
      return addIn(emptyState, commandes, 'accepted')
    }),
  on(CommandeAction.addInArchived, 
    (state, {commandes}) => { 
      return addIn(state, commandes, 'archived')
    }),
  on(CommandeAction.setArchived, 
    (state, {commandes}) => {
      const emptyState = {...state, archived: []}
      return addIn(emptyState, commandes, 'archived')
    }),
  on(CommandeAction.setNewLeader, 
    (state, { commande }) => {
      return editExistingCommande(state, commande)
    }),
  on(CommandeAction.updateOrderQuantities, 
    (state, { commande, arrayName }) => {
      return updateQuantitiesCommande(state, commande, arrayName)
    }),
);

export function commandeReducer(state: CommandeState | undefined, action: Action) {
  return _commandeReducer(state, action);
}