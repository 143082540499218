// @todo Fichier à supprimer une fois API ok

export interface CommandeState {
  showInModal: CommandeDate[]
  idStartInModal: string | null
  pending: CommandeDate[]
  accepted: CommandeDate[]
  archived: CommandeDate[]
  info: CommandeInfo
  sound: boolean
}

export interface Commande {
  delivery_skybill_history: any;
  order_id: string
  order_increment_id: string
  reference: string
  total: string
  is_primary: string
  status: string
  shop_discount: ShopDiscount
  global_status: string
  sub_orders_related: CommandeMultiboutique[]
  is_partial: string
  delivery_type: string
  delivery_date: string
  history_date : string
  user_image: string
  user_name: string
  fees_rate: string
  refund_request: number
  refuse_reason: any
  remaining_refund_items: boolean
  shipping_amount: string
  majority_product: boolean
  coupon_code: DiscountType[]
  discount_amount: string
  items: {
    categories: CommandeCategoryType[]
    menus: CommandeMenu[]
  }
  address: {
    city: string
    customer_telephone: string,
    customer_firstname: string
    customer_lastname: string
    postcode: string
    street_line_1: string
    street_line_2: string
  }
  delivery_address: {
    city: string
    firstname: string
    lastname: string
    phone: string,
    postcode: string
    street_line_1: string
    street_line_2: string
  }
  merchant_information: string | null
  default_weight: number | null
}

export interface DiscountType {
  description: string
  simple_action: string
  discount_amount: string
}

export interface ShopDiscount {
  amount: string
  rules_name: string
}

export interface CommandeDate {
  date: string
  total: number
  orders: Commande[]
}

export interface CommandeInfo {
  delivery_time: string
  nb_orders: number
  nb_received_orders_last_week: number
  nb_received_orders_today: number
  preparation_time: string
  total_orders: string
  chronopostConfig: ChronopostConfig
}

export interface CommandeMenu {
  item_id: string
  product_id: string
  name: string
  total: string
  qty: string
  qty_remaining: string
  product_type: string
  category: string
  unit_price: string
  bundle_info: CommandeMenuType[]
}

export interface CommandeCategoryType {
  type: string
  choices: CommandeCategoryItem[]
}

export interface CommandeMultiboutique {
  name: string,
  is_primary: string,
  status: string,
  id_shop: string,
  app_status: string
}
export interface CommandeMenuType {
  type: string
  choices: CommandeMenuItem[]
}

export interface CommandeMenuItem {
  item_id: string
  name: string
  qty: number

}

export interface CommandeCategoryItem {
  item_id: string,
  product_id: string,
  name: string,
  total: string,
  qty: string,
  product_type: string,
  config: any
  qty_remaining: string
  unit_price: string
}

export interface ChronopostConfig {
  contract: number
  sharedKey: string
  skybillUrl: string
}



export interface getCommandeInterface {
  status?: string[]
  order?: "created_at" | "delivery_date"
  direction?: "ASC" | "DESC"
  page?: number | boolean
  item_per_page?: number | boolean
  id?: number | boolean,
  from?: string | boolean
  to?: string | boolean
}

export interface dataPagination {
  nb_orders: number
  current_page: number
  max_page: number
}
