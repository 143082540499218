<div class="commande__top">
  <div class="notification__container">
    <a class="notification__circle" (click)="toggleNotificationSound()" [ngClass]="(soundActive$ | async) ? 'active' : 'inactive'">
      <img src="/assets/images/sound.png" alt="image notification" />
    </a>
    <div class="notification__number"
      *ngIf="(commandes$ | async).length > 0">
      <span>{{(commandes$ | async).length}}</span>
    </div>
  </div>

  <simple-slide #slider>
    <ng-container *ngFor="let slide of commandes$ | async">
      <div class="commande__new--item" (click)="showNotifCommande(slide.order_id)">
        <span class="item--id">COMMANDE N°{{slide.order_id}}</span>
        <!-- <div class="multiboutique" *ngIf="slide.sub_orders_related.length > 0"><span>M</span></div> -->
        <span class="item--time">{{ slide | preparationHour | async | hour }}</span>
        <category-price [price]="slide.total" [type]="slide.delivery_type"></category-price>
      </div>
    </ng-container>
  </simple-slide>

  <div class="refresh__container">
    <img src="/assets/images/refresh.png" alt="refresh" />
  </div>
</div>
