import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { switchMap, tap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { PrintService } from 'src/app/utils/print/print.service';
import { TicketModalComponent } from '../ticket-modal/ticket-modal.component';
import { ColissimoService } from 'src/app/utils/colissimo/colissimo.service';
import { Store } from '@ngrx/store';
import { selectShowModalTicket } from 'src/app/reducers/settings/settings.selector';
import { MessageService } from 'src/app/utils/message/message.service';

@Component({
  selector: 'colissimo-modal',
  templateUrl: './colissimo-modal.component.html',
  styles: []
})
export class ColissimoModalComponent implements OnInit {
  subscription: Subscription;
  pdfSrc: any;
  pdfContent: any;
  showModalTicket$: Observable<boolean> = this.store.select(selectShowModalTicket);

  constructor(
    public router: Router,
    public store: Store,
    // public printService: PrintService,
    public commandeService: CommandesService,
    public colissimoService: ColissimoService,
    private messageService: MessageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ColissimoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<Commande>
    ) { }

  ngOnInit() {
    this.data.pipe(
      take(1)
    ).subscribe(
      order => {
        this.colissimoService.getPdfColissimo(order.order_id).subscribe(
          pdf => {
            this.pdfSrc = pdf.url
            this.pdfContent = 'data:application/pdf;base64,' + pdf.content;
          }
        )
      }
    )
  }

  mail(){
    this.data.pipe(
      take(1)
    ).subscribe(
      order => {
        this.colissimoService.sendPdfMail(order.order_id).subscribe(
          pdf => {
              this.messageService.openSnackBar('Étiquette envoyée avec succès', 'success')
          },
          error => {
            this.messageService.openSnackBar("Echec lors de l'envoie de l'étiquette", 'error')
          }
        )
      }
    )
  }

  send() {
    this.data.pipe(
      take(1)
    ).subscribe(order => {
      this.commandeService.shippedOrder(order)
      this.router.navigate(['commande'])
      this.dialogRef.close(); 
      this.showModalTicket$.subscribe(bool => {
        if(bool){
          const dialogRef2 = this.dialog.open(TicketModalComponent, {
            panelClass: "modal__ticket",
            disableClose: false,
          });
        }
      })
    })
  }

  closeModal() {
    this.dialogRef.close();
  }
}
