import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { InAppModule } from './screens/inApp/in-app.module';
import { OnBoardingModule } from './screens/onBoarding/on-boarding.module';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { httpInterceptorTokenProviders } from './utils/http/httpInterceptor.service';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PageNotFoundModule } from './screens/other/page-not-found/page-not-found.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    BrowserAnimationsModule,
    HttpClientModule,
    StorageServiceModule,
    MaterialModule,
    // routing
    OnBoardingModule,
    InAppModule,
    PageNotFoundModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    httpInterceptorTokenProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
