import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCommandeToday } from 'src/app/reducers/commande/commande.selector';

@Component({
  selector: 'app-commande-blank',
  templateUrl: './commande-blank.component.html',
  styles: [
  ],
  host: {class: 'commande__details__totalOrders'}
})
export class CommandeBlankComponent implements OnInit {
  today$: Observable<number> = this.store.select(selectCommandeToday)

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void { }

}
