<div class="bulle-status__wrapper">
  <span class="bulle-status__text" *ngIf="wide">{{tradStatus(status)}}</span>   
  <div class="bulle-status" [class]="status" [ngSwitch]="status">
    <mat-icon *ngSwitchCase="'accepted'" class="done">access_time</mat-icon>
    <mat-icon *ngSwitchCase="'ready'"    class="done">done</mat-icon>
    <mat-icon *ngSwitchCase="'shipped'" class="done">done</mat-icon>
    <mat-icon *ngSwitchCase="'canceled'" fontSet="fontello" fontIcon="cancel"></mat-icon>
    <mat-icon *ngSwitchCase="'refused'"  fontSet="fontello" fontIcon="cancel"></mat-icon>
    <mat-icon *ngSwitchDefault class="done">done</mat-icon>
    <!-- <mat-icon>clear</mat-icon> -->
  </div>  
</div>