import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectDarkMode } from 'src/app/reducers/colors/colors.selector';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
})

export class AppLayoutComponent implements OnInit, OnDestroy {
  subscriptionDarkMode: Subscription;

  constructor(private store: Store) {}

  @HostBinding('class.dark') isDarkMode: boolean;

  ngOnDestroy(): void {
    this.subscriptionDarkMode.unsubscribe();
  }
  
  ngOnInit() {
    this.subscriptionDarkMode = this.store.pipe(
      select(selectDarkMode),
    ).subscribe((bool => {
        this.isDarkMode = bool;
    }))
  }
}
