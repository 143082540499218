<div class="loader_container" *ngIf="isLoading">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
<div class="colissimo-modale--item" *ngIf="!isLoading">
    <section class="item--head">
        <span class="title">Formulaire d'étiquette chronopost</span>
        <button class="close__modale" (click)="closeModal()"></button>
    </section>
    <form [formGroup]="chronopostForm" (ngSubmit)="submit()" class="item--list">
      <input type="hidden" formControlName="multi" value="0">
      <button type="button" mat-flat-button color="secondary" class="commande__ready accept" (click)="addDimensions()">
        <mat-icon aria-hidden="true" class="plus">add</mat-icon>
        Ajouter un colis
      </button>
      <div formArrayName="dimensions">
        <div *ngFor="let dimension of dimensions.controls; let i=index">
          <div [formGroupName]="i" class="dimensionForm--item">
            <div class="dimensionForm--item_top">
              <span class="title">Colis n°{{i+1}}</span>
              <button mat-flat-button class="commande__canceled close remove" (click)="removeDimensions(i)">
                <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="trash"></mat-icon>Supprimer
              </button>
            </div>
            <hr>
            <mat-form-field class="gestion__input">
              <mat-label>Poids</mat-label>
              <input matInput type="number" formControlName="weight" min="1">
              <span matSuffix>kg</span>
            </mat-form-field>
            <mat-form-field class="gestion__input">
              <mat-label>Longueur</mat-label>
              <input matInput type="number" formControlName="length" min="1">
              <span matSuffix>cm</span>
            </mat-form-field>
            <mat-form-field class="gestion__input">
              <mat-label>Hauteur</mat-label>
              <input matInput type="number" formControlName="height" min="1">
              <span matSuffix>cm</span>
            </mat-form-field>
            <mat-form-field class="gestion__input">
              <mat-label>Largeur</mat-label>
              <input matInput type="number" formControlName="width" min="1">
              <span matSuffix>cm</span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <div class="button_container">
        <button mat-flat-button [disabled]="disableButton()" color="primary" class="commande__ready accept" (click)="submit()">
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>
            Envoyer
        </button>
    </div>
</div>
