import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ɵConsole } from '@angular/core';
import { Subscription, timer, Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommandeDate, Commande } from 'src/app/models/commande';
import { selectCommandeShowInModal, selectCommandeStartIdSlider, selectCommandePending, selectCommandeSound } from 'src/app/reducers/commande/commande.selector';
import { Store, select } from '@ngrx/store';
import { resetModal, deleteInModal } from 'src/app/reducers/commande/commande.action';
import { first, map, take } from 'rxjs/operators';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { MatDialog } from '@angular/material/dialog';
import { RefuseOrderModalComponent } from 'src/app/_components/refuse-order-modal/refuse-order-modal.component';
import * as moment from 'moment';
import { EditOrderModalComponent } from 'src/app/_components/edit-order-modal/edit-order-modal.component';
import { EditOrderNoProductLeftModal } from 'src/app/_components/edit-order-modal/edit-order-no-product-left-modal/edit-order-no-product-left-modal.component';
import { MessageService } from 'src/app/utils/message/message.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-commande-modale',
  templateUrl: './app-commande-modale.component.html',
  styles: []
})
export class AppCommandeModaleComponent implements OnInit, AfterViewInit, OnDestroy {

  orders$: Observable<Commande[]> = this.store.select(selectCommandeShowInModal)
  pendings$: Observable<Commande[]> = this.store.select(selectCommandePending)
  enabled$: Observable<boolean>
  subscriptionEnabled: Subscription;
  intervalNew: number = 45000
  subscriptionNew: Subscription;
  intervalPending: number = 90000
  subscriptionPending: Subscription;
  editModeIds: string[] = [];
  timeoutPending: any;
  timeoutInProgress: boolean = false;
  rappel: HTMLAudioElement = new Audio('/assets/sounds/rappel.mp3');
  moment: any = moment;
  startPosition: number = 4;
  isRequesting: boolean = false
  editableOrdersForQuantities: Commande[]
  @ViewChild('owlCar') owlCar: any

  constructor(
    private store: Store,
    private commandesService: CommandesService,
    private messageService: MessageService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit() {
    this.enabled$ = this.orders$.pipe(
      map(order => order.length > 0)
    )

    this.orders$.pipe().subscribe(orders => {
      //Si on est en mode edit, on ne met pas à jour la slide pour éviter de devoir rechanger les quantités.
      this.editableOrdersForQuantities = orders.map(current => {
        return this.editModeIds.includes(current.order_id)
        ? this.editableOrdersForQuantities.find(el => el.order_id === current.order_id)
        : current
      })
    })

    this.subscriptionNew = timer(0, this.intervalNew).subscribe(
      () => this.commandesService.refreshNew()
    )

    // Gestion du rappel sonore
    // On fait ici le refresh de pending
    this.subscriptionPending = timer(0, this.intervalPending).subscribe(
      () => this.commandesService.refreshPending()
    )
    this.pendings$.subscribe(
      pendings => {
        // Si il y a des commandes en pending et que le timeout n'est pas encours
        // On met le timeoutInprogress à true et on lance un setTimeout pour le rappel
        if(pendings.length && !this.timeoutInProgress){
          this.startAlert()
        }
        // Si il n'y a pas ou plus de commande
        // On remet tout à zéro
        else if(!pendings.length){
          this.stopAlert()
        }
      }
    )
  }

  ngAfterViewInit(): void {
    // On écoute le changement de enabled
    this.subscriptionEnabled = this.enabled$.subscribe(
      enabled => {
        if(enabled){
          // On va récupérer dans le store la valeur de l'ID de départ
          this.store.pipe(
            select(selectCommandeStartIdSlider),
            first()
          ).subscribe( id => {
            // setTimeout car le rendu du slider n'a pas forcément était fait
            setTimeout(() => {
              if(this.owlCar){
                // Mettre la vitesse d'animation à 0
                // this.owlCar.carouselService.speed(0) // Ne fonctionne pas ;)
                this.customOptions.startPosition || this.owlCar.to("slide_"+id, false)
              }
            }, 0);
          })
        }
      }
    )
  }


  ngOnDestroy() {
    this.subscriptionNew.unsubscribe();
    this.subscriptionEnabled.unsubscribe();
    this.subscriptionPending.unsubscribe();
    this.stopAlert()
  }

  startAlert(): void {
    //On vérifie si l'on a activé ou désactivé les notifications dans le store
    this.store.pipe(
      first(),
      select(selectCommandeSound)
    ).subscribe(
      boolSound => {
        if(boolSound){
          this.timeoutInProgress = true;
          this.timeoutPending = setTimeout( () => {
            // On met le timeoutInprogress à false et déclenche l'alerte
            this.rappel.play()
            this.timeoutInProgress = false
          } , 45000)
        }else{
          this.stopAlert()
        }
      }
    )
  }

  stopAlert(): void {
    clearTimeout(this.timeoutPending)
    this.rappel.pause();
    this.rappel.currentTime = 0;
    this.timeoutInProgress = false;
  }

  //Ajoute la slide en question dans le tableau de slide en mode édition
  edit(slide) {
    this.editModeIds = [...this.editModeIds, slide.order_id]
  }

  /**
   * Changement de quantité d'un menu
   * @param slide
   * @param menu
   * @param index
   */
  changeQtyMenu(slide, menu, index){
    //On cherche l'index de la commande à modifier
    const indexCommande = this.editableOrdersForQuantities.findIndex(element => element.order_id === slide.order_id)
    //On cherche l'index du menu à modifier
    const indexMenu = this.editableOrdersForQuantities[indexCommande].items.menus.findIndex(element => element === menu)
    let commandeIndex = this.editableOrdersForQuantities[indexCommande]
    //On cherche la valeur de la quantité à modifier
    let qtyMenu;
    qtyMenu = parseInt(commandeIndex.items.menus[indexMenu].qty)
    qtyMenu = (qtyMenu - 1).toString()

    //On parse le tableau pour modifier la quantité du produit.
    this.editableOrdersForQuantities = [
      ...this.editableOrdersForQuantities.slice(0,indexCommande),
      {
        ...commandeIndex,
        items: {
          menus: [
            ...commandeIndex.items.menus.slice(0,indexMenu),
            {
              ...commandeIndex.items.menus[indexMenu],
              qty: qtyMenu,
              total: (parseFloat(commandeIndex.items.menus[indexMenu].unit_price) * qtyMenu).toFixed(2)
            },
            ...commandeIndex.items.menus.slice(indexMenu+1),
          ],
          categories: [...commandeIndex.items.categories]
        },
        total: (parseFloat(commandeIndex.total.replace(',', '.')) - parseFloat(menu.unit_price)).toFixed(2)
      },
      ...this.editableOrdersForQuantities.slice(indexCommande+1),
    ]
    //On fixe le startPosition de la slide sur laquelle on a cliqué
    //Pour fix le bug qui fait repartir le slider au début
    this.customOptions.startPosition = index;
    this.setScrollPositionDetail(slide.order_id)
  }

  /**
   * Changement de quantité d'un produit
   * @param slide
   * @param category
   * @param product
   */
  changeQtyProduct(slide, category, product, index) {
    //On cherche l'index de la commande à modifier
    const indexCommande = this.editableOrdersForQuantities.findIndex(element => element.order_id === slide.order_id)
    //On cherche l'index de la commande à modifier
    const indexCategory = this.editableOrdersForQuantities[indexCommande].items.categories.findIndex(element => element === category)
    //On cherche l'index du produit à modifier
    const indexProduct = this.editableOrdersForQuantities[indexCommande].items.categories[indexCategory].choices.findIndex(element => element === product)
    let commandeIndex = this.editableOrdersForQuantities[indexCommande]
    let categoryIndex = commandeIndex.items.categories[indexCategory]
    //On cherche la valeur de la quantité à modifier
    let qtyProduct;
    qtyProduct = parseInt(categoryIndex.choices[indexProduct].qty)
    qtyProduct = (qtyProduct - 1).toString()

    //On parse le tableau pour modifier la quantité du produit.
    this.editableOrdersForQuantities = [
      ...this.editableOrdersForQuantities.slice(0,indexCommande),
      {
        ...commandeIndex,
        items: {
          categories: [
            ...commandeIndex.items.categories.slice(0,indexCategory),
            {
              ...categoryIndex,
              choices: [
                ...categoryIndex.choices.slice(0,indexProduct),
                {
                  ...categoryIndex.choices[indexProduct],
                  qty: qtyProduct,
                  total: (parseFloat(categoryIndex.choices[indexProduct].unit_price) * qtyProduct).toFixed(2)
                },
                ...categoryIndex.choices.slice(indexProduct+1),
              ]
            },
            ...commandeIndex.items.categories.slice(indexCategory+1),
          ],
          menus: [...commandeIndex.items.menus]
        },
        total: (parseFloat(commandeIndex.total.replace(',', '.')) - parseFloat(product.unit_price)).toFixed(2)
      },
      ...this.editableOrdersForQuantities.slice(indexCommande+1),
    ]
    //On fixe le startPosition de la slide sur laquelle on a cliqué
    //Pour fix le bug qui fait repartir le slider au début
    this.customOptions.startPosition = index;
    this.setScrollPositionDetail(slide.order_id)
  }

  //Défini la position du scroll pour éviter que la div remonte à chaque changement de quantité
  setScrollPositionDetail(id: string){
    const divScrollableTop = document.getElementById('scrollable_content_'+id).scrollTop;
    setTimeout(() => {
      document.getElementById('scrollable_content_'+id).scroll(0, divScrollableTop)
    }, 0);
  }

  getData(event){
    this.customOptions.startPosition = event.startPosition
  }

  close() {
    this.store.dispatch(resetModal())
    this.editModeIds = []
  }

  closeModale(slide: Commande) {
    this.store.dispatch(deleteInModal({id: slide.order_id}))
    this.deleteEditModeForSlide(slide);
  }

  refuse(slide: Commande){
    this.store.dispatch(resetModal())
    let dialogRef = this.dialog.open(RefuseOrderModalComponent, {
      panelClass: ["modal__cancel", "modal__refuse"],
      disableClose: true,
      data: slide
    });
    this.deleteEditModeForSlide(slide);
  }

  deleteEditModeForSlide(slide: Commande){
    const index = this.editModeIds.findIndex(element => element === slide.order_id)
    this.editModeIds = [...this.editModeIds.slice(0, index), ...this.editModeIds.slice(index + 1)];
  }

  checkIfQuantityTotalProductsIsNotNull(slide: Commande){
    let quantityTotalProductsIsNotNull = false;
    slide.items.categories.forEach(el => {
      el.choices.forEach(element => {
        if(parseInt(element.qty) > 0){
          quantityTotalProductsIsNotNull = true;
        }
      })
    })
    slide.items.menus.forEach(menu => {
      if(parseInt(menu.qty) > 0){
        quantityTotalProductsIsNotNull = true;
      }
    })
    return quantityTotalProductsIsNotNull;
  }

  accept(slide: Commande){
    this.acceptOrder(slide)
  }

  acceptOrder(slide: Commande){
    // this.deleteEditModeForSlide(slide);
    this.commandesService.acceptCommande(slide)
    this.router.navigate(['/commande/'+slide.order_id])
  }

  validate(slide: Commande){
    /**
     * On vérifie qu'il reste des produits/menu dans la commande
     * Si la slide est en mode édition on ouvre la popup de changement de commande
     * Sinon on l'accepte directement
     */
    if(!this.checkIfQuantityTotalProductsIsNotNull(slide)){
      let dialogRef3 = this.dialog.open(EditOrderNoProductLeftModal, {
        panelClass: ["modal__cancel", "modal__refuse", "modal__edit__order"],
        disableClose: true,
        data: slide
      });
      const elm = document.querySelector<HTMLElement>('.cdk-overlay-container')!;
      elm.style.zIndex = "1002";
      dialogRef3.afterClosed().subscribe(confirm => {
        if(confirm){
          elm.style.zIndex = "1000";
          this.refuse(slide)
        }
      })
    }
    else if(this.editModeIds.includes(slide.order_id)){
      let dialogRef2 = this.dialog.open(EditOrderModalComponent, {
        panelClass: ["modal__cancel", "modal__refuse", "modal__edit__order"],
        disableClose: true,
        data: slide
      });
      const elm = document.querySelector<HTMLElement>('.cdk-overlay-container')!;
      elm.style.zIndex = "1002";
      dialogRef2.afterClosed().subscribe(confirm => {
        if(confirm){
          this.isRequesting = true;
          let items = this.commandesService.setPayloadModificationQuantity(slide) //Construction du payload
          this.commandesService.editQuantityOrder(slide.order_id, items).subscribe(
            _ => {
              this.deleteEditModeForSlide(slide);
              this.commandesService.refreshModalsPending(slide.order_id)
              this.messageService.openSnackBar('La commande a été modifiée avec succès.', 'success')
            }, error => {
              this.messageService.openSnackBar('Echec lors de la modification de la commande', 'error')
            }, () => {
              elm.style.zIndex = "1000";
              this.isRequesting = false;
            }
          )
        }else{
          this.orders$.pipe().subscribe(orders => {
            this.editableOrdersForQuantities = orders
            this.deleteEditModeForSlide(slide);
          })
        }
      })
    }
  }

  /**
   * Config du slider des nouvelles commandes
   */
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    navSpeed: 0,
    items: 1
  }
}
