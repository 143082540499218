import { Component, OnInit, OnDestroy, HostBinding, Inject } from '@angular/core';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { toggleDarkMode } from 'src/app/reducers/colors/colors.action';
import { selectDarkMode } from 'src/app/reducers/colors/colors.selector';
import { Observable, Subscription } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const STORAGE_KEY = "floranova_app_profil"

@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.html',
})
export class AppNavigationComponent implements OnInit, OnDestroy {
  subscriptionDarkMode: Subscription;
  isDarkMode$: Observable<boolean> = this.store.select(selectDarkMode);
  guidePdf: any; 

  constructor(
    private authService: AuthService,
    private store: Store,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }
  
  @HostBinding('class.dark') isDarkMode: boolean;

  ngOnDestroy(): void {
    this.subscriptionDarkMode.unsubscribe();
  }

  ngOnInit() {
    this.guidePdf = this.storage.get(STORAGE_KEY)?.shop.guide_pdf;
    this.subscriptionDarkMode = this.store.pipe(
      select(selectDarkMode),
    ).subscribe((bool => {
        this.isDarkMode = bool;
    }))
  }

  _logout(): void {
    this.authService.logout()
  }

  /**
   * Activation/Desactivation du DarkMode
   */
  toggleDarkMode() {
    this.store.dispatch(toggleDarkMode())
  }

}
