<div class="multiboutique-list-modale">
    <section class="item--head">
        <!-- <div class="multiboutique"><span>M</span></div> -->
        <span class="">Liste des commerçants : </span>
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section>
    <div class="multiboutique__list__item" *ngFor="let item of listUser">       
        <div class="multiboutique__list__user">     
            <div class="multiboutique__list__left">
                <span class="multiboutique__list__picto">
                    <img src="assets/images/king.png" alt="" *ngIf="item.is_primary == '1' && commande.delivery_type == 'chronopost'">
                </span>
                <div class="multiboutique__list__info">
                    <span class="multiboutique__list__name">{{item.name}}</span>
                    <span class="multiboutique__list__place">{{item.name}}</span> 
                </div>
            </div>

            <!-- COLISSIMO -->
            <ng-container *ngIf="commande.delivery_type == 'chronopost'">
                <!-- COMMANDE NON PARTIELLE -->
                <ng-container *ngIf="commande.is_partial == '0'">
                    <div class="multiboutique__list__status">
                        <ng-container *ngIf="item.is_primary == '1'">
                            <div class="multiboutique__list__status--accepted" >
                                <span>Expéditeur</span>
                                <img src="/assets/images/deco/check.png" />
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.is_primary == '0'">
                            <div class="multiboutique__list__status--accepted" *ngIf="item.status == 'pending_ready_colissimo' || item.status == 'ready' || item.status == 'shipped'">
                                <span>Remis<br/>à l'expéditeur</span>
                                <img src="/assets/images/deco/check.png" />
                            </div>
                            <div class="multiboutique__list__status--pending" *ngIf="item.status == 'accepted' || item.status == 'pending' || item.status == 'new'">
                                <span>Non remis<br/> à l'expéditeur</span>
                                <div class="loader_container">
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </div>
                            <div class="multiboutique__list__status--refused" *ngIf="item.status == 'refused'">
                                <span>Refusé</span>
                                <img src="/assets/images/deco/canceled.png" />
                            </div>
                            <div class="multiboutique__list__status--canceled" *ngIf="item.status == 'canceled'">
                                <span>Annulé</span>
                                <img src="/assets/images/deco/canceled.png" />  
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- COMMANDE PARTIELLE -->
                <ng-container *ngIf="commande.is_partial == '1'">
                    <div class="multiboutique__list__status">
                        <div class="multiboutique__list__status--accepted" 
                        *ngIf="item.status == 'accepted' || item.status == 'pending_ready_colissimo' || item.status == 'ready' || item.status == 'shipped'">
                            <span>Accepté</span>
                            <img src="/assets/images/deco/check.png" />
                        </div>
                        <div class="multiboutique__list__status--pending" *ngIf="item.status == 'pending' || item.status == 'new'">
                            <span>En attente</span>
                            <div class="loader_container">
                                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>
                        <div class="multiboutique__list__status--refused" *ngIf="item.status == 'refused'">
                            <span>Refusé</span>
                            <img src="/assets/images/deco/canceled.png" />
                        </div>
                        <div class="multiboutique__list__status--canceled" *ngIf="item.status == 'canceled'">
                            <span>Annulé</span>
                            <img src="/assets/images/deco/canceled.png" />  
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- CLICK & COLLECT / DELIVERY -->
            <ng-container *ngIf="commande.delivery_type !== 'chronopost'">
                <div class="multiboutique__list__status">
                    <div class="multiboutique__list__status--accepted" 
                    *ngIf="item.status == 'accepted'">
                        <span>Accepté</span>
                        <img src="/assets/images/deco/check.png" />
                    </div>
                    <div class="multiboutique__list__status--accepted" *ngIf="item.status == 'ready'">
                        <span>Terminé</span>
                        <img src="/assets/images/deco/check.png" />
                    </div>
                    <div class="multiboutique__list__status--pending" *ngIf="item.status == 'pending' || item.status == 'new'">
                        <span>En attente</span>
                        <div class="loader_container">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                    <div class="multiboutique__list__status--refused" *ngIf="item.status == 'refused'">
                        <span>Refusé</span>
                        <img src="/assets/images/deco/canceled.png" />
                    </div>
                    <div class="multiboutique__list__status--canceled" *ngIf="item.status == 'canceled'">
                        <span>Annulé</span>
                        <img src="/assets/images/deco/canceled.png" />  
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>