import { createReducer, on, Action } from '@ngrx/store';
import * as ColorsAction from './colors.action'
import { ColorsState } from 'src/app/models/colors';

export const initialState: ColorsState = {
  darkMode: false
};


const _colorsReducer = createReducer(
  initialState,
  on(ColorsAction.toggleDarkMode, 
    (state) => {
      return ({...state, darkMode: !state.darkMode})
    }),
);

export function colorsReducer(state: ColorsState | undefined, action: Action) {
  return _colorsReducer(state, action);
}