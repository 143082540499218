import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { take } from 'rxjs/operators';
import { addInArchived, deleteInAccepted } from 'src/app/reducers/commande/commande.action';

@Component({
  selector: 'multiboutique-cancel-modal',
  templateUrl: './multiboutique-cancel-modal.component.html',
  styles: []
})
export class MultiboutiqueCancelModalComponent implements OnInit {
  subscription: Subscription;
  checked = false;

  constructor(
    public router: Router,
    public store: Store,
    public dialogRef: MatDialogRef<MultiboutiqueCancelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Commande
    ) {
      dialogRef.disableClose = true;
    }

  ngOnInit() {
    
  }

  closeModal() {
    this.dialogRef.close();
    const id = this.data.order_id
    this.store.dispatch(deleteInAccepted({id}))
    this.store.dispatch(addInArchived({commandes: [this.data]}))
  }
}
