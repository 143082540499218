import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { switchMap, tap, take, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styles: []
})
export class ChecklistModalComponent implements OnInit {
  subscription: Subscription;
  loader: boolean = true;
  formGroup: UntypedFormGroup;
  listCheckbox: any[]
  disabledButton: boolean = true;
  stringSelectAll: string = 'Tout cocher';

  constructor(
    public router: Router,
    public commandeService: CommandesService,
    public dialogRef: MatDialogRef<ChecklistModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Commande 
    ) { }

  ngOnInit() {
    // this.commandeService.getChecklist().pipe(first()).subscribe(
    //   data => {
    //     let array = []

    //     switch (this.data.delivery_type) {
    //       case 'click_and_collect':
    //         data.click_and_collect.forEach(checkItem => {
    //           array.push({label: checkItem, checked: false})
    //           this.listCheckbox = array
    //         })
    //         break;    
    //       case 'home_delivery':
    //         data.home_delivery.forEach(checkItem => {
    //           array.push({label: checkItem, checked: false})
    //           this.listCheckbox = array
    //         })  
    //         break;   
    //       case 'chronopost':
    //         data.colissimo.forEach(checkItem => {
    //           array.push({label: checkItem, checked: false})
    //           this.listCheckbox = array
    //         })
    //         if (this.data.sub_orders_related.length > 0) {
    //           data.colissimo_not_primary.forEach(checkItem => {
    //             array.push({label: checkItem, checked: false})
    //             this.listCheckbox = array
    //           })
    //         }
    //         break;  
    //       default:
    //         break;
    //     }

    //     this.loader = false;
    //   },
    //   error => {
    //     this.loader = false;
    //   }
    // );  
  }

  getStringSelectAll(){
    if(this.stringSelectAll)
      return 'Tout cocher';
    else return 'Tout décocher';
  }

  toggleCheckbox(ev: MatCheckboxChange, i) {
    const {checked} = ev
    let arrBool = []

    this.listCheckbox[i].checked = checked   
    this.listCheckbox.forEach(el => {
      arrBool.push(el.checked)
    })
    this.disabledButton = arrBool.some(el => el === false)
  }

  toggleCheckboxAll(ev: MatCheckboxChange) {
    const {checked} = ev
    let arrayCheckbox = []
    if(checked){
      this.stringSelectAll = "Tout décocher"
      this.disabledButton = false;
    }else{
      this.stringSelectAll = "Tout cocher"
      this.disabledButton = true;
    }
    
    this.listCheckbox.forEach(el => {
      arrayCheckbox.push({...el, checked: checked})
    })

    this.listCheckbox = arrayCheckbox;
  }

  confirm() {
    this.dialogRef.close(true); 
  }


  closeModal() {
    this.dialogRef.close();
  }
}
