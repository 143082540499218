import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { ReprintColissimoModalComponent } from './reprint-colissimo-modal.component';
import { AppSidebarModule } from 'src/app/_layout/app-sidebar/app-sidebar.module';
import { MaterialModule } from 'src/app/material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer'; 

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule,
    AppSidebarModule,
    MaterialModule,
    PdfViewerModule,  
  ],
  declarations: [
    ReprintColissimoModalComponent
  ],
  exports: [
    ReprintColissimoModalComponent
  ]
})
export class ReprintColissimoModalModule { }
