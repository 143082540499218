<div class="trigger--sidebar">
  <button mat-mini-fab [color]="isOpen ? 'primary' : 'primary'" (click)="toggleSidebar()">
    <mat-icon>{{isOpen ? 'keyboard_arrow_left' : 'keyboard_arrow_left'}}</mat-icon>
  </button>
</div>

<div class="sidebar__wrapper">
  <div class="sidebar__header">
    <!-- LEFT -->
    <date-heure></date-heure>
    <!-- RIGHT -->
    <section [class]="wide ? 'sidebar__header--historique' : 'sidebar__header--commande'">
      <ng-container *ngIf="wide;else noWide">
        <button mat-mini-fab aria-label="Recherche par jour" (click)="picker.open()">
          <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="calendar"></mat-icon>
        </button>
        <button mat-mini-fab aria-label="Recherche par numéro de commande" (click)="openModaleSearch()">
          <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="commande"></mat-icon>
        </button>
        
        <mat-form-field class="invisible-datepicker-field">
          <input 
              matInput 
              [matDatepicker]="picker" 
              [min]="minDate"
              [max]="maxDate"
              (dateChange)="onSelectDatePicker($event.value)" 
          />
          <mat-datepicker #picker></mat-datepicker> 
        </mat-form-field>
      </ng-container>
      <ng-template #noWide>
        <!-- <div class="sidebar__avatar" [style.backgroundImage]="'url('+(profilPhoto$ | async)+')'"> </div> -->
      </ng-template>
    </section>
  </div>

  <ng-container *ngIf="search">
    <div class="sidebar__search">
      <div class="sidebar__search--text" [innerHTML]="searchWording()"></div>
      <a routerLink="/historique" class="sidebar__search--cancel" title="Annuler la recherche"></a>
    </div>
  </ng-container>

  <ng-container *ngFor="let commandeDate of beforeOrders">
    <span matSubheader *ngIf="commandeDate.orders.length">
      <span class="day">En préparation</span>
      <span *ngIf="wide">{{commandeDate.orders.length}} commande(s) / <strong>{{commandeDate.total | total}}</strong></span>
    </span> 
    
    <section class="sidebar__list">
      <sidebar-line *ngFor="let order of commandeDate.orders" [wide]="wide" [order]="order" (click)="onClick()"></sidebar-line>
    </section>  
  </ng-container>

  <!-- Commandes en cours -->
  <ng-container *ngFor="let commandeDate of waitingOrders">
    <span matSubheader *ngIf="commandeDate.orders.length">
      <div class="waiting_container">
        <span class="day">En attente</span>
        <img src="/assets/images/deco/waiting.png" (click)="showWaitingModal()">
      </div>
      <span *ngIf="wide">{{commandeDate.orders.length}} commande(s) / <strong>{{commandeDate.total | total}}</strong></span>
    </span> 
    
    <section class="sidebar__list">
      <sidebar-line *ngFor="let order of commandeDate.orders" [wide]="wide" [order]="order" (click)="onClick()"></sidebar-line>
    </section>  
  </ng-container>
  
  <!-- Historique -->
  <ng-container *ngFor="let commandeDate of orders">
    <span matSubheader *ngIf="commandeDate.orders.length">
      <span class="day">{{commandeDate.date | daySidebar:formatToday}}</span>
      <span *ngIf="wide">{{commandeDate.orders.length}} commande(s) / <strong>{{commandeDate.total | total}}</strong></span>
    </span> 
    
    <section class="sidebar__list">
      <sidebar-line *ngFor="let order of commandeDate.orders" [wide]="wide" [order]="order" (click)="onClick()"></sidebar-line>
    </section>  
  </ng-container>
  
  <ng-container *ngIf="max_page > 1 && wide">
    <mat-paginator 
      [length]="max_page"
      [pageSize]="1"
      [pageIndex]="_pageIndex"
      hidePageSize="true"
      (page)="pageChange($event)"
    ></mat-paginator>
  </ng-container>
</div>