import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { CommandeDetailsComponent } from './commande-details/commande-details.component';
import { CommandeNewComponent } from './commande-new/commande-new.component';
import { CommandeComponent } from './commande.component';
import { CommandeBlankComponent } from './commande-blank/commande-blank.component';
import { AppSidebarModule } from 'src/app/_layout/app-sidebar/app-sidebar.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { SimpleSlideModule } from 'src/app/_components/simple-slide/simple-slide.module';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { CommandeTestComponent } from './commande-test/commande-test.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CancelOrderModalModule } from 'src/app/_components/cancel-order-modal/cancel-order-modal.module';
import { CategoryPriceModule } from 'src/app/_components/category-price/category-price.module';
import { ColissimoModalModule } from 'src/app/_components/colissimo-modal/colissimo-modal.module';
import { TicketModalModule } from 'src/app/_components/ticket-modal/ticket-modal.module';
import { StripeModalModule } from 'src/app/_components/stripe-modal/stripe-modal.module';
import { MultiboutiqueListModalModule } from 'src/app/_components/multiboutique-list/multiboutique-list-modal.module';
import { MultiboutiqueReadyModalModule } from 'src/app/_components/multiboutique-ready/multiboutique-ready-modal.module';
import { MultiboutiqueCancelModalModule } from 'src/app/_components/multiboutique-cancel/multiboutique-cancel-modal.module';
import { MultiboutiqueWaitingModalModule } from 'src/app/_components/multiboutique-waiting/multiboutique-waiting-modal.module';
import { ReprintColissimoModalModule } from 'src/app/_components/reprint-colissimo-modal/reprint-colissimo-modal.module';
import { FraisCommissionModalModule } from 'src/app/_components/frais-commission-modal/frais-commission-modal.module';
import { ChecklistModalModule } from 'src/app/_components/checklist-modal/checklist-modal.module';
import { RefuseOrderModalModule } from 'src/app/_components/refuse-order-modal/refuse-order-modal.module';
import { CatalogMultiModalModule } from 'src/app/_components/catalog-multi-modal/catalog-multi-modal.module';
import { EditOrderModalModule } from 'src/app/_components/edit-order-modal/edit-order-modal.module';
import { ChronopostModalComponentModule } from 'src/app/_components/chronopost-modal/chronopost-modal.module';



@NgModule({
   imports: [
      CommonModule,
      MaterialModule,
      AppSidebarModule,
      RouterModule,
      CarouselModule,
      SimpleSlideModule,
      CustomPipeModule, 
      ReactiveFormsModule,
      CancelOrderModalModule,
      RefuseOrderModalModule,
      ChecklistModalModule,
      FraisCommissionModalModule,
      ChronopostModalComponentModule,
      ColissimoModalModule,
      ChecklistModalModule,
      MultiboutiqueListModalModule,
      CatalogMultiModalModule,
      EditOrderModalModule,
      MultiboutiqueReadyModalModule,
      MultiboutiqueCancelModalModule,
      MultiboutiqueWaitingModalModule,
      ReprintColissimoModalModule,
      TicketModalModule,
      CategoryPriceModule, 
   ],
   declarations: [
      CommandeDetailsComponent,
      CommandeNewComponent,   
      CommandeComponent, 
      CommandeBlankComponent,    
      CommandeTestComponent,
   ]
})
export class CommandeModule { }