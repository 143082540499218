import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ShopState } from 'src/app/models/shop';
import { ProfilState } from 'src/app/models/profil';


const _selectShop = createFeatureSelector< ShopState>('shop');
const _selectProfil = createFeatureSelector< ProfilState>('profil');


export const selectShopAll = createSelector(
  _selectShop,
  (state: ShopState) => state
);

export const selectShopVisualUrl = createSelector(
  _selectShop,
  (state: ShopState) => state.visual_url
);

export const selectShopEmail = createSelector(
  _selectShop,
  (state: ShopState) => state.contact_email
);

export const selectShopDescription = createSelector(
  _selectShop,
  (state: ShopState) => state.description
);

export const selectShopDelay = createSelector(
  _selectShop,
  (state: ShopState) => state.preparation_time
);

export const selectShopDisableToday = createSelector(
  _selectShop,
  (state: ShopState) => state.time_slot_disable_today
);

export const selectShopExceptionalClosings = createSelector(
  _selectShop,
  (state: ShopState) => state.exceptionalClosings
);

export const selectShopHoraires = createSelector(
  _selectShop,
  (state: ShopState) => state.horaires
);

export const selectQuota = createSelector(
  _selectShop,
  (state: ShopState) => state.order_quota
);

export const selectAnalyticsParameters = createSelector(
  _selectProfil,
  (state: ProfilState) => (
    {
      ccid: state.shop.website_id, 
      coid: state.shop.id_shop,
      prid: state.selectedProfil.entity_id,
    }
  )
);