import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bulle-status',
  templateUrl: './bulle-status.component.html',
  styles: []
})
export class BulleStatusComponent implements OnInit {
  traductionStatusLabel = {
    accepted: "Acceptée",
    pending: "En attente",
    ready: "Terminée",
    refused: "Refusée",
    canceled: "Annulée",
    new: "Nouvelle",
    shipped: "Expédiée"
  }
  @Input() status: string
  @Input() wide: boolean

  constructor() { }

  ngOnInit() {
  }

  tradStatus(status:string): string{
    return this.traductionStatusLabel[status] || "Autre"
  }

}
