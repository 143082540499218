import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntypedFormArray, FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { RefundService } from 'src/app/utils/refund/refund.service';

@Component({
  selector: 'frais-commission-modal',
  templateUrl: './frais-commission-modal.component.html',
  styles: []
})
export class FraisCommissionModalComponent implements OnInit {
  subscription: Subscription;
  formFraisCommission: any[] = [];
  formGroup: UntypedFormGroup;
  payload: any;
  commande: Commande
  isLoading: boolean = false;
  isConfirmation: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public commandesService: CommandesService,
    public store: Store,
    private messageService: MessageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FraisCommissionModalComponent>,
    public refundService: RefundService,
    @Inject(MAT_DIALOG_DATA) public data: Observable<Commande>
    ) {}

  ngOnInit() {
    this.data.pipe(
      take(1)
    ).subscribe(  
      order => {     
        this.commande = order;   
        if(order.items.menus.length > 0){
          order.items.menus.forEach(el => {
            if(parseInt(el.qty_remaining) > 0){
              let arrayQty = []
              let quantity = parseInt(el.qty_remaining)
              for (let index = 1; index <= quantity; index++) {
                arrayQty.push(index)
              }
              this.formFraisCommission = [...this.formFraisCommission, {
                comment: '',
                checked: false,
                qty: arrayQty,
                product_name: el.name,
                product_id: el.item_id
              }]
            }
          })
        }
        if(order.items.categories.length > 0){
          order.items.categories.forEach(categorie => {
            categorie.choices.forEach(el => {
              if(parseInt(el.qty_remaining) > 0){
                let arrayQty = []
                let quantity = parseInt(el.qty_remaining)
                let nameWithConfig = ""
                if(el.config.length > 0){
                  nameWithConfig = `${el.name} (`;
                  el.config.forEach((elem, index) => {
                    nameWithConfig += `${elem}`
                    if(index != el.config.length -1){
                      nameWithConfig += ` / `
                    }
                  })
                  nameWithConfig += `)`
                }else nameWithConfig = el.name
                for (let index = 1; index <= quantity; index++) {
                  arrayQty.push(index)
                }
                this.formFraisCommission = [...this.formFraisCommission, {
                  comment: '',
                  checked: false,
                  qty: arrayQty,
                  product_name: nameWithConfig,
                  product_id: el.item_id
                }]
              }
            });
          })
        }    
      }
    )

    
    this.formGroup = new UntypedFormGroup({
      formFraisCommission: new UntypedFormArray([])
    });
    
    const formArray = this.formGroup.get('formFraisCommission') as UntypedFormArray;
    
    this.formFraisCommission.forEach(item => {
      formArray.push(new UntypedFormGroup({
        checked: new UntypedFormControl(item.checked),
        name: new UntypedFormControl(item.product_name),
        qty: new UntypedFormControl(1),
        comment: new UntypedFormControl(item.comment),
        id: new UntypedFormControl(item.product_id),
      }))
    })
  }

  disableButton() {
    return this.formGroup.value.formFraisCommission.filter(f => f.checked).length <= 0
  }

  checkConfirmation() {
    this.isConfirmation = true;
  }
  
  cancelConfirmation() {
    this.isConfirmation = false;
  }

  submit() {
    this.isLoading = true;
    const selectedItems = this.formGroup.value.formFraisCommission.filter(f => f.checked);
    let arrayProduct = [];
    selectedItems.forEach(el => {
      arrayProduct = [...arrayProduct, {
        id: el.id,
        name: el.name,
        comment: el.comment,
        qty: el.qty,
      }]
    });
    let payload = {
      refund: {
        products: arrayProduct,
        suborder_reference: this.commande.reference,
        suborder_id: this.commande.order_id
      }
    }
    
    this.refundService.demandeRemboursement(payload).subscribe(    
      success => {  
        this.dialogRef.close();
        this.messageService.openSnackBar('Demande de remboursement effectuée', 'success')
        this.isLoading = false;
        this.commandesService.refreshArchived(this.route.queryParams).subscribe()
      },
      error => {
        this.dialogRef.close();
        this.messageService.openSnackBar('Demande de remboursement non effectuée', 'error')
        this.isLoading = false;
      }
    );
  }
  
  closeModal() {
    this.dialogRef.close();
  }
}
