<mat-form-field>
  <mat-label>Numéro commande :</mat-label>
  <input matInput type="text" [formControl]="commandeId">
</mat-form-field>
<mat-form-field>
  <mat-label>Statut :</mat-label>
  <mat-select [formControl]="status">
    <mat-option *ngFor="let val of allStatus" [value]="val">
      {{val}}  
    </mat-option>
  </mat-select>
</mat-form-field>

<button mat-flat-button color="primary" type="submit" (click)="send()">
  Modifier
</button>