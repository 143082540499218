import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Component({
  selector: 'date-heure',
  templateUrl: './date-heure.component.html',
  styles: [
  ],
  host:{
    class: 'sidebar__header--date-hour'
  }
})
export class DateHeureComponent implements OnInit {
  date$: Observable<any> 
  
  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    console.log(this.authService.getStoreId())
    this.date$ = timer(0, 1000).pipe(
      map( () => moment())
    )
  }

  getStoreName(): string {
    return this.authService.getStoreName();
  }

}
