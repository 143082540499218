import { createAction, props } from '@ngrx/store';
import { ShopState, ShopHoraire } from 'src/app/models/shop';

export enum ShopTypes {
  setShop = "[Shop] Met à jour la boutique",
  setVisualUrl = "[Shop] Met à jour l'image de la boutique",  
  setEmail = "[Shop] Met à jour l'email de la boutique",  
  setDescription = "[Shop] Met à jour la description de la boutique",  
  setDelay = "[Shop] Met à jour le délai de livraison de la boutique",
  setDisableToday = "[Shop] Met a jour la disponibilité quotidienne de la boutique",
  setExceptionalClosure = "[Shop] Met à jour les dates de fermetures exceptionnelles",
  addExceptionalClosure = "[Shop] Ajoute une date de fermeture exceptionnelle",
  removeExceptionalClosure = "[Shop] Supprime une date de fermeture exceptionnelle",
  setHoraires = "[Shop] Met à jour les horaires de la boutique",
  setQuota = "[Shop] Met à jour le quota"
}

export const setShop = createAction(
  ShopTypes.setShop,
  props<{ shop: ShopState }>()
);

export const setVisualUrl = createAction(
  ShopTypes.setVisualUrl,
  props<{ visual_url: string }>()
);

export const setEmail = createAction(
  ShopTypes.setEmail,
  props<{ contact_email: string }>()
);

export const setDescription = createAction(
  ShopTypes.setDescription,
  props<{ description: string }>()
);

export const setDelay = createAction(
  ShopTypes.setDelay,
  props<{ preparation_time: string }>()
);

export const setDisableToday = createAction(
  ShopTypes.setDisableToday,
  props<{ time_slot_disable_today: string }>()
);

export const setExceptionalClosure = createAction(
  ShopTypes.setExceptionalClosure,
  props<{ dates: string[] }>()
);

export const addExceptionalClosure = createAction(
  ShopTypes.addExceptionalClosure,
  props<{ date: string }>()
);

export const removeExceptionalClosure = createAction(
  ShopTypes.removeExceptionalClosure,
  props<{ date: string }>()
);

export const setQuota = createAction(
  ShopTypes.setQuota,
  props<{ order_quota: string }>()
);

export const setHoraires = createAction(
  ShopTypes.setHoraires,
  props<{ horaires: ShopHoraire[] }>()
);

