import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ColorsState } from 'src/app/models/colors';


const _selectColors = createFeatureSelector< ColorsState>('colors');


export const selectDarkMode = createSelector(
  _selectColors,
  (state: ColorsState) => state.darkMode
);
