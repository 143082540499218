<div class="commande__container">
    <app-sidebar 
        [wide]="false" 
        [orders]="commandes$ |async"
        [beforeOrders]="commandesBefore$ | async"
        [waitingOrders]="commandesWaiting$ | async"
    ></app-sidebar>
    <div class="commande__right">
        <commande-new></commande-new>
        <router-outlet></router-outlet>
    </div>
</div>
