<ng-template #isDelivery>
    Livraison à
</ng-template>
<ng-template #isclickAndCollect>
    Retrait au magasin 
</ng-template>

<p class="warning_refus">
    Avant de passer à côté de cette vente, tentez de trouver une solution alternative avec le client.<br/> 
    <span class="warnig_customer">Client : <b>{{data?.address?.customer_firstname}} {{data?.address?.customer_lastname}}</b></span><br/>
    <span class="warnig_phone">Numéro : <b>{{data?.address?.customer_telephone}}</b></span><br/>
    <span class="warnig_delivery">
        <ng-container 
            *ngIf="data?.delivery_type == 'click_and_collect'; then isclickAndCollect; else isDelivery">
        </ng-container>  : 
        <b>{{data?.delivery_address?.city}} ({{data?.delivery_address?.postcode}})</b>
    </span>
</p>
<button mat-button class="confirm refuse_order_cancel" (click)="closeModal()">J'appelle le client</button>  

<button class="refuse_order_button" (click)="confirmCancel()">Confirmer l'annulation</button>
